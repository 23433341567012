:root {
  --dft-lt-blue: #3698d3;
  --dft-dk-blue: #2c3e50;
  --dft-gray: #4e4e4e;
  --dft-yellow: #F1C20C;
  --dft-green: #00FF00;
  --dft-background-color: #73a7cf;
  --color-disabled: rgba(0, 0, 0, 0.3);
  --ion-font-family: "Raleway";
}

:root {
  --ion-color-dft-lt-blue: #3698d3;
  --ion-color-dft-lt-blue-rgb: 54, 152, 211;
  --ion-color-dft-lt-blue-contrast: #000000;
  --ion-color-dft-lt-blue-contrast-rgb: 0, 0, 0;
  --ion-color-dft-lt-blue-shade: #3086ba;
  --ion-color-dft-lt-blue-tint: #4aa2d7;
}

.ion-color-dft-lt-blue {
  --ion-color-base: var(--ion-color-dft-lt-blue);
  --ion-color-base-rgb: var(--ion-color-dft-lt-blue-rgb);
  --ion-color-contrast: var(--ion-color-dft-lt-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dft-lt-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-dft-lt-blue-shade);
  --ion-color-tint: var(--ion-color-dft-lt-blue-tint);
}

:root {
  --ion-color-dft-dk-blue: #2c3e50;
  --ion-color-dft-dk-blue-rgb: 44, 62, 80;
  --ion-color-dft-dk-blue-contrast: #ffffff;
  --ion-color-dft-dk-blue-contrast-rgb: 255, 255, 255;
  --ion-color-dft-dk-blue-shade: #273746;
  --ion-color-dft-dk-blue-tint: #415162;
}

.ion-color-dft-dk-blue {
  --ion-color-base: var(--ion-color-dft-dk-blue);
  --ion-color-base-rgb: var(--ion-color-dft-dk-blue-rgb);
  --ion-color-contrast: var(--ion-color-dft-dk-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dft-dk-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-dft-dk-blue-shade);
  --ion-color-tint: var(--ion-color-dft-dk-blue-tint);
}

:root {
  --ion-color-dft-gray: #4e4e4e;
  --ion-color-dft-gray-rgb: 78, 78, 78;
  --ion-color-dft-gray-contrast: #ffffff;
  --ion-color-dft-gray-contrast-rgb: 255, 255, 255;
  --ion-color-dft-gray-shade: #454545;
  --ion-color-dft-gray-tint: #606060;
}

.ion-color-dft-gray {
  --ion-color-base: var(--ion-color-dft-gray);
  --ion-color-base-rgb: var(--ion-color-dft-gray-rgb);
  --ion-color-contrast: var(--ion-color-dft-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dft-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-dft-gray-shade);
  --ion-color-tint: var(--ion-color-dft-gray-tint);
}

:root {
  --ion-color-dft-yellow: #F1C20C;
  --ion-color-dft-yellow-rgb: 241, 194, 12;
  --ion-color-dft-yellow-contrast: #000000;
  --ion-color-dft-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-dft-yellow-shade: #d4ab0b;
  --ion-color-dft-yellow-tint: #f2c824;
}

.ion-color-dft-yellow {
  --ion-color-base: var(--ion-color-dft-yellow);
  --ion-color-base-rgb: var(--ion-color-dft-yellow-rgb);
  --ion-color-contrast: var(--ion-color-dft-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dft-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-dft-yellow-shade);
  --ion-color-tint: var(--ion-color-dft-yellow-tint);
}

:root {
  --ion-color-dft-green: #00FF00;
  --ion-color-dft-green-rgb: 0, 255, 0;
  --ion-color-dft-green-contrast: #000000;
  --ion-color-dft-green-contrast-rgb: 0, 0, 0;
  --ion-color-dft-green-shade: #00e000;
  --ion-color-dft-green-tint: #1aff1a;
}

.ion-color-dft-green {
  --ion-color-base: var(--ion-color-dft-green);
  --ion-color-base-rgb: var(--ion-color-dft-green-rgb);
  --ion-color-contrast: var(--ion-color-dft-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dft-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-dft-green-shade);
  --ion-color-tint: var(--ion-color-dft-green-tint);
}

:root {
  --ion-color-dft-background-color: #73a7cf;
  --ion-color-dft-background-color-rgb: 115, 167, 207;
  --ion-color-dft-background-color-contrast: #000000;
  --ion-color-dft-background-color-contrast-rgb: 0, 0, 0;
  --ion-color-dft-background-color-shade: #6593b6;
  --ion-color-dft-background-color-tint: #81b0d4;
}

.ion-color-dft-background-color {
  --ion-color-base: var(--ion-color-dft-background-color);
  --ion-color-base-rgb: var(--ion-color-dft-background-color-rgb);
  --ion-color-contrast: var(--ion-color-dft-background-color-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dft-background-color-contrast-rgb);
  --ion-color-shade: var(--ion-color-dft-background-color-shade);
  --ion-color-tint: var(--ion-color-dft-background-color-tint);
}

